import Simplemodal from '../../plugins/simplemodal.js';

const fullScreenMenu = new Simplemodal({
  trigger : "data-fullScreenMenu-trigger",
  clickOutSideClose : true
})

const hamburger = new Simplemodal({
  trigger : "data-simplemodal-trigger",
  clickOutSideClose : true
})

document.addEventListener("DOMContentLoaded", function() {
  // ページが読み込まれたときに実行するコード

  // // ルートディレクトリであるかどうかを判定
  // if (window.location.pathname === '/' || window.location.pathname === '/index.php') {
  //     // ルートディレクトリの場合、要素を非表示にする
  //     var rootElement = document.getElementById('js-fullScreenMenu-button');
  //     if (rootElement) {
  //         rootElement.style.display = 'none';
  //     }
  // }

  // var rootElement = document.getElementById('js-fullScreenMenu-button');
  //     if (rootElement) {
  //         rootElement.style.display = 'none';
  //     }
});
