// window.onload = function () {
//   if (window.location.pathname === "/") {
//     // ページがロード完了したら2秒後に非表示にする
//     setTimeout(function () {
//       const loadingText = document.getElementById("js-loading");

//       if (loadingText) {
//         loadingText.classList.add("is-loaded");
//       }
//     }, 3000); // 2秒（2000ミリ秒）待ってから非表示にする
//   }
// };

// function setCookie(key, value) {
//   document.cookie = key + "=" + value + ";";
// }

// function getCookie(key) {
//   const cookies = document.cookie;
//   const cookiesAry = cookies.split(";");

//   for (let i = 0; i < cookiesAry.length; i++) {
//     let cookie = cookiesAry[i].split("=");
//     if (cookie[0] === key) {
//       return cookie;
//     }
//   }
// }

// const keyName = "visited";
// const keyValue = true;

// window.onload = function () {
//   if (window.location.pathname === "/") {
//     const loadingText = document.getElementById("js-loading");

//     if (!sessionStorage.getItem(keyName)) {
//       //sessionStorageにキーと値を追加
//       sessionStorage.setItem(keyName, keyValue);

//       //ここに初回アクセス時の処理
//       // ページがロード完了したら2秒後に非表示にする
//       setTimeout(function () {

//         if (loadingText) {
//           loadingText.classList.add("is-loaded");
//         }
//       }, 3000); // 2秒（2000ミリ秒）待ってから非表示にするs
//     } else {
//       //ここに通常アクセス時の処理
//       console.log("訪問済みです");
//       loadingText.style.display = "none";
//     }
//   }
// };

function setCookie(key, value) {
  document.cookie = key + "=" + value + ";";
}

const keyName = "visited";
const keyValue = true;
const loadingText = document.getElementById("js-loading");

if (loadingText !== null) {
  // DOMContentLoaded イベントリスナーを追加
  document.addEventListener("DOMContentLoaded", function () {
    if (!sessionStorage.getItem(keyName)) {
      sessionStorage.setItem(keyName, keyValue);
      console.log("初めての訪問です");

      if (window.location.pathname === "/") {
        // ページがロード完了したら2秒後に非表示にする
        setTimeout(function () {
          if (loadingText) {
            loadingText.classList.add("is-loaded");
          }
        }, 2000); // 2秒（2000ミリ秒）待ってから非表示にする
      }
    } else {
      console.log("訪問済みです");

      // 通常のアクセス時にも非表示にする
      loadingText.style.display = "none";
    }
  });
}
