if (window.location.pathname === "/") {
  let tsushimaZoom = $(".js-top-recruit__in");
  let tsushimaBg = $(".js-top-recruit__bg");
  let tsushimaBlock = $(".js-top-recruit__text");
  let minZoom = 1;
  let maxZoom = 2;

  $(window).on("scroll", function (e) {
    let vh = window.innerHeight / 100;
    let scrollTop = document.documentElement.scrollTop;

    let start = $(".p-top-recruit__inner").offset().top - 50 * vh;

    if (scrollTop > start) {
      tsushimaZoom.addClass("is-active");
      tsushimaBg.addClass("is-active");
      tsushimaBlock.addClass("is-active");
    } else {
      tsushimaZoom.removeClass("is-active");
      tsushimaBg.removeClass("is-active");
      tsushimaBlock.removeClass("is-active");
    }
  });
}
