import { callbackify } from 'util';
import Tab from '../../plugins/tab.js';
const elements = document.querySelectorAll(".js-tab");
for (const element of elements) {
  new Tab(element, {
    defaultOpenPanel: 0
  });
}


//historyページのタブの下矢印アイコンクリックでもタブが動作するように
const historyTabItemArrow = Array.from(document.querySelectorAll(".p-history-tab__item :is(.myfont)"));

if (historyTabItemArrow) {
  historyTabItemArrow.forEach((arrow) => {
    arrow.addEventListener("click", (e) => {
      e.target.closest(".js-tab-button").click();
    });
  });
}


//historyページの開いているタブボタンのfont-weightを変更しタブと同じインデックス番号の年号を連動させる
const historyTabButton = Array.from(document.querySelectorAll(".p-history-tab-list :is(.js-tab-button)"));
const historyYearItems = Array.from(document.querySelectorAll(".p-history-year-item"));

if (historyTabButton) {
  historyTabButton.forEach((button) => {

    button.addEventListener("click", () => {
      historyTabButton.forEach((target) => {
        if (target.getAttribute("aria-selected") === "true") {
          //targetがtrueのときはis-active classを付与
          target.classList.add("is-active");
          //targetがtrueのindex番号を取得
          const index = historyTabButton.indexOf(target);
          //trueだったindex番号と同じ番号のhistoryYearItems内の--circleへis-active classを付与
          historyYearItems[index].querySelector(".--circle").classList.add("is-active");
          //trueだったindex番号と同じ番号のhistoryYearItemsの--boldへis-active classを付与
          historyYearItems[index].querySelectorAll(".--bold").forEach((e) => {
            e.classList.add("is-active");
          })
        } else if (target.getAttribute("aria-selected") === "false") {
          //targetがfalseのときはis-active classを削除
          target.classList.remove("is-active");
          //targetがfalseのindex番号を取得
          const index = historyTabButton.indexOf(target);
          //index番号と同じ番号のhistoryYearItemsの--circleに付与されているis-active classを削除
          historyYearItems[index].querySelector(".--circle").classList.remove("is-active");
          //index番号と同じ番号のhistoryYearItemsの--boldに付与されているis-active classを削除
          historyYearItems[index].querySelectorAll(".--bold").forEach((e) => {
            e.classList.remove("is-active");
          })
        }
      });
    });
  });
}





// accordion.init();
// new Tab($(".js-tab"), {
//   defaultOpenPanel: 0
// });

// $(".js-tab").each(function(i, v){
//   var $that = $(this);
//   new Tab($that, {
//     defaultOpenPanel: 0
//   });
// })